import { breakpoints } from '@/styles/breakpoints';
export const theme = {
  colors: {
    primary: '#4771E0',
    background: '#F1F1F1',
    text: '#000000',
    white: '#FFFFFF',
    link: '#4771E0',
    inputBorder: '#DCDCDC',
    formLabel: '#9C9C9C',
    selectedColor: '#CED3D5',
    tableBorder: '#D9D9D9',
    pagination: '#D9D9D9',
    breadcrumbs: '#9C9C9C',
  },
  fontSizes: {
    xs: 10,
    sm: 12,
    base: 14,
    midium: 16,
    large: 18,
  },
  breakpoints,
} as const;
export type Theme = typeof theme;

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}
