const dateTimeDigitsConverter = (time: number) => {
  if (time.toString().length === 1) {
    return '0' + time.toString();
  } else {
    return time.toString();
  }
};
export const dateTimeFilterConverter = (dateTime: string | undefined) => {
  if (dateTime === undefined) {
    return undefined;
  } else {
    const JSTTime = new Date(dateTime);
    // const UTCTime = new Date(JSTTime.setHours(JSTTime.getHours() - 9));
    const [month, day, year] = [JSTTime.getMonth(), JSTTime.getDate(), JSTTime.getFullYear()];
    const [hour, minutes] = [JSTTime.getHours(), JSTTime.getMinutes()];
    const filterTime = `${year}-${dateTimeDigitsConverter(month + 1)}-${dateTimeDigitsConverter(day)}T${dateTimeDigitsConverter(hour)}:${dateTimeDigitsConverter(minutes)}`;
    return filterTime.replace('T', ' ').concat(':00.000');
  }
}