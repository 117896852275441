import { RootState } from '../store';

export const authoritySelector = (state: RootState) => {
  return state.admin.authorities;
};

export const isLoggedInSelector = (state: RootState) => {
  return state.admin.isLoggedIn;
};

export const errorSelector = (state: RootState) => {
  return state.admin.error;
};

export const staffGymListSelector = (state: RootState) => {
  return state.admin.staffGymList;
};
