import { StoreGetUserFilterRequestStatusesEnum } from '../api/main';
import { UserStatus } from '../store/slice/userSlice';

export const statusEnumConverter = (status: UserStatus): StoreGetUserFilterRequestStatusesEnum => {
  switch (status) {
    case 'subscribe':
      return StoreGetUserFilterRequestStatusesEnum.Subscribe;
    case 'subValid0TmpUseThisMonth':
      return StoreGetUserFilterRequestStatusesEnum.SubValid0TmpUseThisMonth;
    case 'subValidNullTmpUseThisMonth':
      return StoreGetUserFilterRequestStatusesEnum.SubValidNullTmpUseThisMonth;
    case 'subValid0TmpUseNotThisMonth':
      return StoreGetUserFilterRequestStatusesEnum.SubValid0TmpUseNotThisMonth;
    case 'subValidNullTmpUseNotThisMonth':
      return StoreGetUserFilterRequestStatusesEnum.SubValidNullTmpUseNotThisMonth;
    case 'subValid0TmpUseNull':
      return StoreGetUserFilterRequestStatusesEnum.SubValid0TmpUseNull;
    case 'banned':
      return StoreGetUserFilterRequestStatusesEnum.Banned;
    case 'voluntaryDeleted':
      return StoreGetUserFilterRequestStatusesEnum.VoluntaryDeleted;
    case 'subValidNullTmpUseNull':
      return StoreGetUserFilterRequestStatusesEnum.SubValidNullTmpUseNull;
    default:
      return StoreGetUserFilterRequestStatusesEnum.Banned;
  }
};
