import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LoginRequest, StaffGymListRequestOrderEnum } from '@/api/main';
import { mainApi, removeAxiosAuthorizationHeader, setAxiosAuthorizationHeader } from '@/libs/axios';
import { dataDogClearUser, dataDogSetUser } from '@/resources/dataDogHelpers';
import { adminSlice, extractTokenInfo } from '@/store/slice/adminSlice';
import { configSlice } from '@/store/slice/configSlice';
import { gymSlice } from '@/store/slice/gymSlice';
import { logSlice } from '@/store/slice/logSlice';
import { messageSlice } from '@/store/slice/messageSlice';
import { saleSummariesSlice } from '@/store/slice/salesSummariesSlice';
import { userSlice } from '@/store/slice/userSlice';
import { accessTokenStorage, refreshTokenStorage } from '@/utils/localStorage';

import { usePostLogin } from './usePostLogin';
import { usePostLogout } from './usePostLogout';

export const useInitAuth = () => {
  const dispatch = useDispatch();
  const { clearLoginInfo } = useLogout();
  const { setAuthInfo } = useLogin();

  useEffect(() => {
    const accessToken = accessTokenStorage.get();
    if (accessToken) {
      setAuthInfo(accessToken);
    } else {
      clearLoginInfo();
    }
  }, [dispatch, clearLoginInfo, setAuthInfo]);
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const { postLogout } = usePostLogout();
  const router = useRouter();

  const clearLoginInfo = useCallback(() => {
    dispatch(adminSlice.actions.reset());
    dispatch(userSlice.actions.reset());
    dispatch(logSlice.actions.reset());
    dispatch(configSlice.actions.reset());
    dispatch(gymSlice.actions.reset());
    dispatch(messageSlice.actions.reset());
    dispatch(saleSummariesSlice.actions.reset());
    accessTokenStorage.remove();
    refreshTokenStorage.remove();
    dataDogClearUser();
    removeAxiosAuthorizationHeader();
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      await postLogout();
      clearLoginInfo();
      router.push('/auth/login');
    } catch (e) {
      // TODO: エラーハンドリング追加する
    }
  };

  return { clearLoginInfo, handleLogout };
};

export const useLogin = () => {
  const dispatch = useDispatch();
  const { clearLoginInfo } = useLogout();
  const router = useRouter();
  const { postLogin } = usePostLogin();
  const [isShownGymAlert, setIsShownGymAlert] = useState(false);

  const setAuthInfo = async (accessToken: string) => {
    setAxiosAuthorizationHeader(accessToken);
    dataDogSetUser(extractTokenInfo(accessToken).sub);
    try {
      const gymListRes = await mainApi.staffGetStaffGymList({
        order: StaffGymListRequestOrderEnum.Asc,
        pageNumber: undefined,
      });
      const staffGymList = gymListRes.data.gyms;

      if (!staffGymList.length) {
        clearLoginInfo();
        if (!isShownGymAlert) {
          window.alert(
            'ジムが紐づいていないため、ログインが正常に行われませんでした。管理者・担当SVに連絡お願いします。',
          );
          setIsShownGymAlert(true);
        }
        return;
      }

      dispatch(adminSlice.actions.loggedIn({ accessToken, staffGymList }));
    } catch (error) {
      clearLoginInfo();
    }
  };

  const handleLogin = async (params: LoginRequest) => {
    try {
      const res = await postLogin(params);
      await setAuthInfo(res.data.accessToken);
      accessTokenStorage.set(res.data.accessToken);
      refreshTokenStorage.set(res.data.refreshToken);

      router.push('/');
    } catch (e) {
      dispatch(adminSlice.actions.loginFailed());
    }
  };

  return { handleLogin, setAuthInfo };
};
