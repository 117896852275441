import { mainApi } from 'src/libs/axios';

export const usePostLogout = () => {
  const postLogout = () => {
    return mainApi.staffLogout();
  };

  return {
    postLogout,
  };
};
