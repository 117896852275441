export const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

export const ENV_STATE = {
  dev: 'dev',
  staging: 'staging',
  production: 'production',
} as const;

export const DATADOG_ENV = process.env.NEXT_PUBLIC_DATADOG_ENV ?? ENV_STATE.dev;

export const SP_VIEW_MAX_WIDTH = 1023;
