import '../styles/global.css';

import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from 'src/store/store';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from '@/providers/AuthProvider';
import { AxiosProvider } from '@/providers/AxiosProvider';
import GymRedirectProvider from '@/providers/GymRedirectProvider';
import { initDataDog } from '@/resources/dataDogHelpers';
import { theme } from '@/styles/theme';

// eslint-disable-next-line @typescript-eslint/naming-convention
const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    initDataDog();
  }, []);

  return (
    <Provider store={store}>
      <AxiosProvider>
        <AuthProvider>
          <GymRedirectProvider>
            <ThemeProvider theme={theme}>
              <Component {...pageProps} />
            </ThemeProvider>
          </GymRedirectProvider>
        </AuthProvider>
      </AxiosProvider>
    </Provider>
  );
};

export default App;
