import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TemporaryUseGym } from '../../api/main';

export type AuthorityType = {
  userView?: number;
  sale?: number;
  gym?: number;
  log?: number;
  message?: number;
  config?: number;
};

export type AdminState = {
  authorities: AuthorityType;
  isLoggedIn: boolean | null;
  loading: boolean;
  error: string;
  staffGymList: TemporaryUseGym[];
};

type Authorities = {
  UserView?: number;
  Sale?: number;
  Gym?: number;
  Log?: number;
  Message?: number;
  Config?: number;
};

type TokenInfo = {
  authorities: Authorities;
  sub: number;
  role: string;
  iat: number;
};

export const extractTokenInfo = (token: string): TokenInfo => {
  const encoded = token.match(/\.(.*)\./gi);
  if (encoded === null) {
    throw 'decode error.';
  }
  return JSON.parse(Buffer.from(encoded[0].slice(1).slice(0, -1), 'base64').toString());
};

export const createAuthorities = (token: string) => {
  const authorities = extractTokenInfo(token).authorities;
  const newObj: AuthorityType = {
    userView: authorities.UserView,
    sale: authorities.Sale,
    gym: authorities.Gym,
    log: authorities.Log,
    message: authorities.Message,
    config: authorities.Config,
  };

  return newObj;
};

const initialState: AdminState = {
  authorities: {
    userView: 0,
    sale: 0,
    gym: 0,
    log: 0,
    message: 0,
    config: 0,
  },
  isLoggedIn: null,
  loading: false,
  error: '',
  staffGymList: [],
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    reset: (state) => {
      state.authorities = {
        userView: 0,
        sale: 0,
        gym: 0,
        log: 0,
        message: 0,
        config: 0,
      };
      state.isLoggedIn = false;
      state.error = '';
      state.loading = initialState.loading;
      state.staffGymList = [];
    },
    loggedIn: (state, action: PayloadAction<{ accessToken: string; staffGymList: TemporaryUseGym[] }>) => {
      const authorities = createAuthorities(action.payload.accessToken);
      state.authorities = authorities;
      state.isLoggedIn = true;
      state.error = '';
      state.loading = false;
      state.staffGymList = action.payload.staffGymList;
    },
    loginFailed: (state) => {
      state.error = 'error';
      state.loading = false;
    },
  },
});

export const adminReducer = adminSlice.reducer;
