import { RefreshTokenRequest } from 'src/api/main';
import { mainApi } from 'src/libs/axios';

export const usePostRefreshToken = () => {
  const postRefreshToken = (params: RefreshTokenRequest) => {
    return mainApi.staffRefreshToken(params);
  };

  return {
    postRefreshToken,
  };
};
