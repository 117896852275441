import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/**
 * routerのreplaceが連続で呼ばれることを防いでreplaceできるhooks
 * https://github.com/vercel/next.js/issues/39128#issuecomment-1198115040
 */
export const useSafeRouterReplace = () => {
  const [isRouteChanging, setIsRouteChanging] = useState(false);

  const handleRouteChangeComplete = () => {
    setIsRouteChanging(false);
  };
  const router = useRouter();

  const safeRouterReplace = (path: string) => {
    if (isRouteChanging) {
      return;
    }
    setIsRouteChanging(true);
    router.replace(path);
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router, setIsRouteChanging]);
  return { safeRouterReplace };
};
