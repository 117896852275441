import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from 'src/store/selector/adminSelector';

import Loading from '@/components/atoms/Loading';
import { useInitAuth } from '@/hooks/useAuth';

interface Props {
  children: ReactNode;
}

/**
 * 認証状態の初期化を行う。取得まではchildrenを表示しない
 */
export const AuthProvider: FC<Props> = ({ children }) => {
  useInitAuth();
  const isLoggedIn = useSelector(isLoggedInSelector);

  const router = useRouter();

  const shouldRedirect = isLoggedIn === false && router.pathname !== '/auth/login';

  if (shouldRedirect) {
    router.push('/auth/login');
    return null;
  }

  if (isLoggedIn === null) {
    return <Loading size={32} isLoading />;
  }

  return <>{children}</>;
};
