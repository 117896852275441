import { LoginRequest } from 'src/api/main';
import { mainApi } from 'src/libs/axios';

export const usePostLogin = () => {
  const postLogin = (params: LoginRequest) => {
    return mainApi.staffLogin(params);
  };

  return {
    postLogin,
  };
};
