import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreGetRolesResponseRoles, StoreGetStaffsResponseStaffs } from '../../api/main/api';
import { mainApi } from '../../libs/axios';

interface ConfigState {
  roles: StoreGetRolesResponseRoles[];
  staffs: StoreGetStaffsResponseStaffs[];
  selected?: StoreGetStaffsResponseStaffs;
}

const initialState: ConfigState = {
  roles: [] as StoreGetRolesResponseRoles[],
  staffs: [] as StoreGetStaffsResponseStaffs[],
};

interface StaffEditArgs {
  staffId: number;
  gymIds: number[];
  name: string;
  roleId: number;
}

interface StaffCreateArgs {
  email: string;
  password: string;
  name: string;
  roleId: number;
  gymIds: number[];
}

export const onGetRole = createAsyncThunk<{ roles: StoreGetRolesResponseRoles[] }>('configs/onGetRole', async () => {
  const res = await mainApi.configStoreGetRoles();
  return { roles: res.data.roles };
});

export const onGetStaff = createAsyncThunk<{ staffs: StoreGetStaffsResponseStaffs[] }>(
  'configs/onGetStaff',
  async () => {
    const res = await mainApi.configStoreGetStaffs();
    return { staffs: res.data.staffs };
  },
);

export const onEditStaff = createAsyncThunk<void, { args: StaffEditArgs }>('configs/onEditStaff', async (params) => {
  const res = await mainApi.configStoreEditStaff({
    staffId: params.args.staffId,
    gymIds: params.args.gymIds,
    name: params.args.name,
    roleId: params.args.roleId,
  });
});

export const onDeleteStaff = createAsyncThunk<void, { args: number }>('configs/onDeleteStaff', async (params) => {
  await mainApi.configStoreDeleteStaff({
    staffId: params.args,
  });
});

export const onCreateStaff = createAsyncThunk<void, { args: StaffCreateArgs }>(
  'configs/onCreateStaff',
  async (params) => {
    const res = await mainApi.configStoreCreateStaffs({
      email: params.args.email,
      password: params.args.password,
      name: params.args.name,
      roleId: params.args.roleId,
      gymIds: params.args.gymIds,
    });
  },
);

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setSelectedStaff: (state, action: PayloadAction<StoreGetStaffsResponseStaffs>) => {
      state.selected = action.payload;
    },
    reset: (state) => {
      state.roles = initialState.roles;
      state.staffs = initialState.staffs;
      state.selected = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onGetRole.pending, () => {});
    builder.addCase(onGetRole.fulfilled, (state, action) => {
      state.roles = action.payload.roles;
    });
    builder.addCase(onGetRole.rejected, () => {});
    builder.addCase(onGetStaff.pending, () => {});
    builder.addCase(onGetStaff.fulfilled, (state, action) => {
      state.staffs = action.payload.staffs;
    });
    builder.addCase(onGetStaff.rejected, () => {});
    builder.addCase(onEditStaff.pending, () => {});
    builder.addCase(onEditStaff.fulfilled, () => {});
    builder.addCase(onEditStaff.rejected, () => {});
    builder.addCase(onDeleteStaff.pending, () => {});
    builder.addCase(onDeleteStaff.fulfilled, () => {});
    builder.addCase(onDeleteStaff.rejected, () => {});
    builder.addCase(onCreateStaff.pending, () => {});
    builder.addCase(onCreateStaff.fulfilled, () => {});
    builder.addCase(onCreateStaff.rejected, () => {});
  },
});

export const configReducer = configSlice.reducer;
