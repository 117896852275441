import { StoreGetUserFilterRequestGenderEnum } from "../api/main";
import { UserGender } from "../store/slice/userSlice";

export const genderEnumConverter = (gender: Omit<UserGender, ''>): StoreGetUserFilterRequestGenderEnum => {
  switch(gender) {
    case 'Man': return StoreGetUserFilterRequestGenderEnum.Man;
    case 'Woman': return StoreGetUserFilterRequestGenderEnum.Woman;
    case 'No': return StoreGetUserFilterRequestGenderEnum.No;
    case 'Other': return StoreGetUserFilterRequestGenderEnum.Other;
    default: return StoreGetUserFilterRequestGenderEnum.Man;
  }
}