import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TemporaryUseGym } from '@/api/main';
import { staffGymListSelector } from '@/store/selector/adminSelector';

const useGym = () => {
  const router = useRouter();

  const gymList = useSelector(staffGymListSelector);

  const [selectedGym, setSelectedGym] = useState<TemporaryUseGym | undefined | null>(null);

  const getSelectedGymId = (): number => {
    const gymId = router.query.gym_id;
    return Number(gymId);
  };

  useEffect(() => {
    const gymId = router.query.gym_id;
    const res = gymList.find((x) => x.id === Number(gymId));
    setSelectedGym(res);
  }, [router.query.gym_id, gymList]);

  return {
    selectedGym,
    gymList,
    getSelectedGymId,
  };
};

export default useGym;
