import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GymInfo, GymStatusInfo } from '../../api/main';
import { mainApi } from '../../libs/axios';

export interface GymState {
  loading: boolean;
  gymStatuses: GymStatusInfo[];
  gymInfos: GymInfo[];
  error: string;
}

const initialState: GymState = {
  gymInfos: [],
  gymStatuses: [],
  loading: false,
  error: '',
};

export const onGetGymInfos = createAsyncThunk<{ gyms: GymInfo[] }>('gym/getAllGyms', async () => {
  const res = await mainApi.storeGymStoreGetGymInfos();
  return { gyms: res.data.gymInfos };
});

export const onGetGymStatuses = createAsyncThunk<GymStatusInfo[]>('gym/onGetGyms', async () => {
  const res = await mainApi.storeGymStoreGetGymStatuses();
  return res.data.gymStatuses;
});

export const gymSlice = createSlice({
  name: 'gym',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = initialState.loading;
      state.gymStatuses = initialState.gymStatuses;
      state.gymInfos = initialState.gymInfos;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onGetGymStatuses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(onGetGymStatuses.fulfilled, (state, action) => {
      state.gymStatuses = action.payload;
      state.loading = false;
    });
    builder.addCase(onGetGymStatuses.rejected, (state) => {
      state.loading = false;
      state.error = 'error';
    });
    builder.addCase(onGetGymInfos.pending, () => {});
    builder.addCase(onGetGymInfos.fulfilled, (state, action) => {
      state.gymInfos = action.payload.gyms;
    });
    builder.addCase(onGetGymInfos.rejected, () => {});
  },
});

export const gymReducer = gymSlice.reducer;
