import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  size: number;
  isLoading?: boolean;
};

const Loading: FC<Props> = (props) => {
  return <LoadingElement size={props.size} />;
};

export default Loading;

const loading = keyframes`
    0%{transform:rotate(0deg)}
    100%{transform:rotate(360deg)}
`;

const LoadingElement = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: ${({ size }) => `${size}px`};
  border: ${({ size }) => `${size / 8}px`} solid #f1f1f1;
  border-top-color: #4771e0;
  box-sizing: border-box;
  margin-top: ${({ size }) => `${size / 2}px`};
  margin-left: ${({ size }) => `${size / 2}px`};
  animation: ${loading} 1.2s linear infinite;
  -webkit-animation: ${loading} 1.2s linear infinite;
`;
