import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useGym from '@/hooks/useGym';
import { useSafeRouterReplace } from '@/hooks/useSafeRouterReplace';
import { isLoggedInSelector } from '@/store/selector/adminSelector';

interface GymRedirectProviderProps {
  children: ReactNode;
}

/**
 * リダイレクト処理を行うProvider
 */
const GymRedirectProvider = ({ children }: GymRedirectProviderProps) => {
  const { selectedGym, gymList } = useGym();
  const [isReady, setIsReady] = useState(false);
  const router = useRouter();
  const { gym_id } = router.query;
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { safeRouterReplace } = useSafeRouterReplace();

  useEffect(() => {
    if (isLoggedIn === false) {
      setIsReady(true);
    }

    if (!router.isReady) return;

    if (gymList.length === 0) return;

    const isGymExists = gymList.some((gym) => gym.id === Number(gym_id));

    // URLにgym_idが存在する場合、その有効性を確認
    if (!isGymExists && gymList[0]?.id) {
      safeRouterReplace(`/${gymList[0].id}`);
      return;
    }

    setIsReady(true);
  }, [gymList, gym_id, isLoggedIn, router, selectedGym, safeRouterReplace]);

  if (!isReady) {
    return null;
  }

  return <>{children}</>;
};

export default GymRedirectProvider;
