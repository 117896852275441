import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GateLogWithUserInfo, PaymentLogWithUserInfo } from '../../api/main';
import { mainApi } from '../../libs/axios';
import { dateTimeFilterConverter } from '../../utils/dateTimeFilterConverter';
import { entranceOrderEnumConverter } from '../../utils/entranceOrderEnumConverter';
import { entrancePlanEnumConverter } from '../../utils/entrancePlanEnumConverter';
import { paymentOrderEnumConverter } from '../../utils/paymentOrderEnumConverter';
import { paymentPlanEnumConverter } from '../../utils/paymentPlanEnumConverter';

export type EntranceOrder = 'asc' | 'desc';
export type PaymentOrder = 'asc' | 'desc';
export type Plan = 'unspecified' | 'subscribe' | 'temporaryUse';

interface EntranceFilterState {
  page: number;
  order: EntranceOrder;
  firstName: string;
  lastName: string;
  plan: Plan;
  gymId: number;
  registerStartDateTime: string | undefined;
  registerEndDateTime: string | undefined;
}

interface PaymentFilterState {
  page: number;
  firstName: string;
  lastName: string;
  plan: Plan;
  order: PaymentOrder;
  registerStartDateTime: string | undefined;
  registerEndDateTime: string | undefined;
}

interface EntranceState {
  filters: EntranceFilterState;
  results: GateLogWithUserInfo[];
  selected?: GateLogWithUserInfo;
  numOfEntrances: number;
  numOfPages: number;
}

interface PaymentState {
  filters: PaymentFilterState;
  results: PaymentLogWithUserInfo[];
  selected?: PaymentLogWithUserInfo;
  numOfPayments: number;
  numOfPages: number;
}

interface LogState {
  entrance: EntranceState;
  payment: PaymentState;
}

const date = new Date();
// const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
// const [hour, minutes] = [date.getHours(), date.getMinutes()];
// const initialFromTime = `${year}-${dateTimeDigitsConverter(month + 1)}-${dateTimeDigitsConverter(day)}T00:00`;
// const initialToTime = `${year}-${dateTimeDigitsConverter(month + 1)}-${dateTimeDigitsConverter(day)}T${dateTimeDigitsConverter(hour)}:${dateTimeDigitsConverter(minutes)}`
const initialFromTime = undefined;
const initialToTime = undefined;
const initialState: LogState = {
  entrance: {
    filters: {
      page: 1,
      order: 'desc',
      firstName: '',
      lastName: '',
      plan: 'unspecified',
      gymId: 0,
      registerStartDateTime: initialFromTime,
      registerEndDateTime: initialToTime,
    },
    results: [] as GateLogWithUserInfo[],
    numOfEntrances: 0,
    numOfPages: 0,
  },
  payment: {
    filters: {
      page: 1,
      firstName: '',
      lastName: '',
      plan: 'unspecified',
      order: 'desc',
      registerStartDateTime: initialFromTime,
      registerEndDateTime: initialToTime,
    },
    results: [] as PaymentLogWithUserInfo[],
    numOfPayments: 0,
    numOfPages: 0,
  },
};

export const onGetEntrance = createAsyncThunk<
  { entrances: GateLogWithUserInfo[]; numOfEntrances: number; numOfPages: number },
  {
    filter: {
      page: number;
      order: EntranceOrder;
      firstName: string;
      lastName: string;
      plan: Plan;
      gymId: number;
      registerStartDateTime: string | undefined;
      registerEndDateTime: string | undefined;
    };
    gymId: number;
  }
>('logs/onGetEntrance', async (params) => {
  const order = entranceOrderEnumConverter(params.filter.order);
  const plan = params.filter.plan === 'unspecified' ? undefined : entrancePlanEnumConverter(params.filter.plan);
  const res = await mainApi.storeLogStoreGetGateLogs({
    firstName: params.filter.firstName ? params.filter.firstName : undefined,
    lastName: params.filter.lastName ? params.filter.lastName : undefined,
    categoryOfPlan: plan,
    gateId: undefined,
    gymId: params.filter.gymId,
    pageNumber: params.filter.page,
    registerStartDatetime: dateTimeFilterConverter(params.filter.registerStartDateTime),
    registerEndDatetime: dateTimeFilterConverter(params.filter.registerEndDateTime),
    order,
  });
  return {
    entrances: res.data.gateLogs ?? [],
    numOfEntrances: res.data.numberOfGateLogs,
    numOfPages: res.data.numberOfPages,
  };
});

export const onGetPayment = createAsyncThunk<
  { payments: PaymentLogWithUserInfo[]; numOfPayments: number; numOfPages: number },
  {
    filter: {
      page: number;
      firstName: string;
      lastName: string;
      plan: Plan;
      order: PaymentOrder;
      registerStartDateTime: string | undefined;
      registerEndDateTime: string | undefined;
    };
    gymId: number;
  }
>('users/onGetPayment', async (params) => {
  const order = paymentOrderEnumConverter(params.filter.order);
  const plan = params.filter.plan === 'unspecified' ? undefined : paymentPlanEnumConverter(params.filter.plan);
  const res = await mainApi.storeLogStoreGetPurchaseLogs({
    gymId: params.gymId,
    pageNumber: params.filter.page,
    firstName: params.filter.firstName === '' ? undefined : params.filter.firstName,
    lastName: params.filter.lastName === '' ? undefined : params.filter.lastName,
    categoryOfPlan: plan,
    registerStartDatetime: dateTimeFilterConverter(params.filter.registerStartDateTime),
    registerEndDatetime: dateTimeFilterConverter(params.filter.registerEndDateTime),
    order,
  });
  return {
    payments: res.data.paymentLogs ?? [],
    numOfPayments: res.data.numberOfPaymentLogs,
    numOfPages: res.data.numberOfPages,
  };
});

export const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    reset: (state) => {
      //stateのもう一つ深い階層まで指定しないと反映されない
      state.entrance = initialState.entrance;
      state.payment = initialState.payment;
    },
    setSelectingUserWithGateLog: (state, action: PayloadAction<GateLogWithUserInfo>) => {
      state.entrance.selected = action.payload;
    },
    setSelectingUserWithPaymentLog: (state, action: PayloadAction<PaymentLogWithUserInfo>) => {
      state.payment.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onGetPayment.pending, (state, action) => {
      state.payment.filters = action.meta.arg.filter;
    });
    builder.addCase(onGetPayment.fulfilled, (state, action) => {
      state.payment.results = action.payload.payments;
      state.payment.numOfPayments = action.payload.numOfPayments;
      state.payment.numOfPages = action.payload.numOfPages;
    });
    builder.addCase(onGetPayment.rejected, () => {});
    builder.addCase(onGetEntrance.pending, (state, action) => {
      state.entrance.filters = action.meta.arg.filter;
    });
    builder.addCase(onGetEntrance.fulfilled, (state, action) => {
      state.entrance.results = action.payload.entrances;
      state.entrance.numOfEntrances = action.payload.numOfEntrances;
      state.entrance.numOfPages = action.payload.numOfPages;
    });
    builder.addCase(onGetEntrance.rejected, () => {});
  },
});

export const logReducer = logSlice.reducer;
